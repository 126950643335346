import nl from "@/locales/generated/nl-be";
import { defaultLocale } from "./canonicalSettings";

export default defineI18nConfig(() => ({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  fallbackWarn: false,
  missingWarn: false,
  messages: {
    "nl-be": nl,
  },
}));
