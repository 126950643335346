// @ts-nocheck
const resource = {
  "account.action.delete-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder adres"])},
  "account.action.forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord vergeten"])},
  "account.action.sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
  "account.address.default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard"])},
  "account.address.unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen adres gekend."])},
  "account.form.action.add-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres toevoegen"])},
  "account.form.action.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
  "account.form.action.edit-addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer adressen"])},
  "account.form.action.show-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk alles"])},
  "account.form.message.warning.no-additional-addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen additionele adresgegevens aanwezig."])},
  "account.form.title.additional-addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additionele adresgegevens"])},
  "account-info.form.label.first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
  "account-info.form.label.last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
  "account-info.form.label.vat-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTW nummer"])},
  "account-info.message.success.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gegevens werden aangepast."])},
  "account.label.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
  "account.label.username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
  "account.message.error.sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er deed zich een fout voor bij het authenticeren. Contacteer ons indien het probleem blijft aanhouden."])},
  "account.message.newsletter-subscription.general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent ingeschreven op 'Algemeen'."])},
  "account.message.warning.delete-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenst u dit adres te verwijderen?"])},
  "account.title.addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresboek"])},
  "account.title.dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn account"])},
  "account.title.delivery-addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsadressen"])},
  "account.title.edit-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord wijzigen"])},
  "account.title.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactgegevens"])},
  "account.title.invoice-addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturatieadressen"])},
  "account.title.navigation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigatie"])},
  "account.title.newsletters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwsbrieven"])},
  "account.title.orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellingen"])},
  "account.title.recent-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recente bestellingen"])},
  "account.title.standard-delivery-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard verzendadres"])},
  "account.title.standard-invoice-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard factuuradres"])},
  "address.form.label.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stad/Gemeente"])},
  "address.form.label.company-form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vennootschapsvorm"])},
  "address.form.label.company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsnaam"])},
  "address.form.label.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "address.form.label.delivery-address-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is het standaard leveradres"])},
  "address.form.label.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
  "address.form.label.first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
  "address.form.label.invoice-address-default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit is het standaard factuuradres"])},
  "address.form.label.last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
  "address.form.label.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huisnummer"])},
  "address.form.label.number-extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoeging"])},
  "address.form.label.phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
  "address.form.label.postal-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
  "address.form.label.save-in-address-book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit adres ook opslaan in het adresboek"])},
  "address.form.label.street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straatnaam"])},
  "address.form.label.vat-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTW nummer"])},
  "address.form.message.info.invoice-for-customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indien u facturatie wenst, gelieve dan in te loggen of een account aan te maken om hiervan gebruik te maken."])},
  "address.form.placeholder.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een land"])},
  "address.form.title.address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
  "address.form.title.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactgegevens"])},
  "age-verification.modal.action.no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee, ik ben jonger dan 18 jaar"])},
  "age-verification.modal.action.yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, ik ben ouder dan 18 jaar"])},
  "age-verification.modal.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bent u ouder dan 18 jaar?"])},
  "auth.label.sign-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])},
  "auth.message.error.invalid-credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onjuiste gegevens bij het inloggen."])},
  "auth.message.error.sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het inloggen."])},
  "cart-gift-info.label.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boodschap"])},
  "cart-gift-info.message.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg hier een boodschap toe"])},
  "cart-gift-info.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadeautje?"])},
  "cart-item.label.quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal"])},
  "cart-logistics-delivery.action.add-new-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw adres toevoegen"])},
  "cart-logistics-delivery.action.choose-alternate-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ander adres kiezen"])},
  "cart-logistics-delivery-info.label.delivery-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsadres"])},
  "cart-logistics-delivery-info.label.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzending via DPD"])},
  "cart-logistics-delivery-info.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendgegevens"])},
  "cart-logistics-delivery.label.same-as-invoice-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hetzelfde als het facturatie adres"])},
  "cart-logistics-delivery.placeholder.select-alternate-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een optie"])},
  "cart-logistics-delivery.title.delivery-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leveringsadres"])},
  "cart-logistics-delivery.title.delivery-cost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendingskosten"])},
  "cart-logistics-delivery.title.invoice-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturatieadres"])},
  "cart-logistics-delivery.title.new-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw adres"])},
  "cart-logistics.option.delivery.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendkosten worden in de volgende stap berekend. Gratis verzending in België vanaf €300."])},
  "cart-logistics.option.delivery.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzending"])},
  "cart-logistics.option.pickup.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis"])},
  "cart-logistics.option.pickup.label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afhaling in winkel (Kortrijk)"])},
  "cart-logistics-pickup.label.pickup-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewenste afhaaldatum"])},
  "cart-logistics-pickup.title.address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ons adres"])},
  "cart-logistics-pickup.title.pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afhalen kan op"])},
  "cart-payment.action.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalen"])},
  "cart-payment.message.error.create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een fout opgetreden bij het aanmaken van het betalingsverzoek. Probeer het later nog eens."])},
  "cart-payment.message.info.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve te betalen via onderstaande knop."])},
  "cart-payment.status.authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling werd goedgekeurd."])},
  "cart-payment.status.cancelled-by-cardholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling werd afgebroken."])},
  "cart-payment.status.declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling werd geweigerd."])},
  "cart-payment.status.technical-problem-occurred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling is mislukt."])},
  "cart-payment.status.timed-out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling duurde te lang."])},
  "cart-steps.delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzending"])},
  "cart-steps.information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw gegevens"])},
  "cart-steps.logistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistiek"])},
  "cart-steps.payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling"])},
  "cart-steps.pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afhaling"])},
  "cart-steps.review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])},
  "cart-totals.label.delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzending"])},
  "cart-totals.label.pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afhaling"])},
  "cart-totals.label.sub-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotaal"])},
  "cart-totals.label.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaalprijs"])},
  "cart-totals.label.vat-excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["excl. BTW"])},
  "cart-totals.label.vat-included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["incl. BTW"])},
  "cart-totals.message.info.delivery-cost-calculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berekend in de volgende stap"])},
  "cart-totals.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw bestelling"])},
  "catalog.action.filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
  "catalog-filters.action.show-products": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toon ", _interpolate(_named("Count")), " producten"])},
  "catalog-filters.label.show-less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minder"])},
  "catalog-filters.label.show-more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Toon ", _interpolate(_named("count")), " meer"])},
  "catalog-filters.title.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs"])},
  "catalog.label.pager": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Producten ", _interpolate(_named("FromCount")), "-", _interpolate(_named("ToCount")), " van ", _interpolate(_named("TotalCount"))])},
  "catalog.label.sorting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorteer op"])},
  "catalog.message.warning.no-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen producten gevonden."])},
  "catalog-sorting.option.default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard"])},
  "catalog-sorting.option.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
  "catalog-sorting.option.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs"])},
  "catalog-sorting.option.relevance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relevantie"])},
  "catalog.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogus"])},
  "catalog.title.current-filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidige filters"])},
  "checkout.action.back-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar winkelwagen"])},
  "checkout.action.confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling betalen en plaatsen"])},
  "checkout.action.continue-shopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doorgaan met winkelen"])},
  "checkout.action.continue-without-sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verder zonder aanmelden"])},
  "checkout.action.go-to-checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verder naar bestellen"])},
  "checkout.action.go-to-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verder naar verzending"])},
  "checkout.action.go-to-pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga naar afhaling"])},
  "checkout.action.go-to-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verder naar overzicht"])},
  "checkout.header.ex-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijzen excl. btw"])},
  "checkout.label.action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie"])},
  "checkout.label.ex-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(excl. btw)"])},
  "checkout.label.order-quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal"])},
  "checkout.label.price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs"])},
  "checkout.label.product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
  "checkout.label.sub-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotaal"])},
  "checkout.message.info.checkout-without-sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of wenst u te bestellen zonder aanmelden"])},
  "checkout.message.info.price-validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producten werden aangepast aan de nieuwe prijs."])},
  "checkout.message.info.quantity-validation-by-stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producten werden aangepast aan de beschikbare hoeveelheid."])},
  "checkout.message.info.sign-in-for-invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indien u een factuur wenst, is het noodzakelijk om zich aan te melden of te registreren."])},
  "checkout.message.info.status-validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producten werden verwijderd omwille van de nieuwe status."])},
  "checkout.message.warning.no-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen producten gevonden."])},
  "checkout.remove-item.confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verwijder artikel"])},
  "checkout.remove-item.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenst u dit artikel te verwijderen?"])},
  "checkout.remove-item.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winkelwagen"])},
  "checkout.text.confirmation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bedankt voor uw bestelling. Uw ordernummer is #", _interpolate(_named("OrderId")), ". Zodra de bestelling is verwerkt, ontvang je een bevestiging per e-mail."])},
  "checkout.text.confirmation-payment-pending": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uw bestelling #", _interpolate(_named("OrderId")), " is ontvangen. Zodra deze is betaald en verwerkt, ontvang je een bevestiging per e-mail."])},
  "checkout.title.cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winkelwagen"])},
  "checkout.title.confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order bevestiging"])},
  "checkout.title.continue-without-sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verder zonder aanmelden"])},
  "checkout.title.delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzending"])},
  "checkout.title.information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw gegevens"])},
  "checkout.title.new-customers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe klanten"])},
  "checkout.title.pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afhaling"])},
  "checkout.title.remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft u nog bijkomende opmerkingen omtrent uw bestelling?"])},
  "checkout.title.review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overzicht"])},
  "checkout.title.your-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw bestelling"])},
  "common.action.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
  "common.action.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
  "common.action.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
  "common.action.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder"])},
  "common.action.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
  "common.action.registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registreren"])},
  "common.action.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewaren"])},
  "common.title.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technisch probleem"])},
  "common.title.info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "common.title.success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
  "common.title.warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarschuwing"])},
  "contact.form.action.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versturen"])},
  "contact.form.label.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stad/Gemeente"])},
  "contact.form.label.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "contact.form.label.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "contact.form.label.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw bericht"])},
  "contact.form.label.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
  "contact.form.label.phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
  "contact.form.placeholder.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een land"])},
  "contact.form.subject.product-request": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aanvraag voor product ", _interpolate(_named("SKU")), " - ", _interpolate(_named("Name"))])},
  "engraving.form.action.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versturen"])},
  "engraving.form.label.address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straat en huisnummer"])},
  "engraving.form.label.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stad/Gemeente"])},
  "engraving.form.label.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
  "engraving.form.label.format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formaat van de fles"])},
  "engraving.form.label.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam en voornaam"])},
  "engraving.form.label.phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
  "engraving.form.label.postal-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
  "engraving.form.label.text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tekst om te graveren"])},
  "footer.bottom-bar.copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2024 Wijnen De Clerck - All rights reserved"])},
  "form.validation.accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld dient aangevinkt te worden."])},
  "form.validation.confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De wachtwoorden zijn niet gelijk."])},
  "form.validation.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld dient een e-mail adres te zijn."])},
  "form.validation.invalid-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze datum is ongeldig."])},
  "form.validation.min-length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dit veld heeft een minimum lengte van ", _interpolate(_named("MinLength"))])},
  "form.validation.required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld is verplicht."])},
  "form.validation.vat-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve een geldig nummer in te geven, zoals voorbeeld BE0123 456 789"])},
  "gift-info.message.error.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er deed zich een fout voor bij het opslaan van het bericht. Contacteer ons indien het probleem blijft aanhouden."])},
  "gift-info.message.success.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booschap werd opgeslagen."])},
  "header.actions.label.account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
  "header.actions.label.authentication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen"])},
  "header.actions.label.cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winkelwagen"])},
  "header.actions.label.dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn account"])},
  "header.actions.label.menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "header.actions.label.registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account aanmaken"])},
  "header.actions.label.wish-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn verlanglijst"])},
  "header.search.label.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoekterm"])},
  "header.search.label.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken"])},
  "header.search.label.view-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk alles"])},
  "header.search.message.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er deed zich een fout voor bij het zoeken. Contacteer ons indien het probleem blijft aanhouden."])},
  "header.search.placeholder.input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ben op zoek naar..."])},
  "mail-order-confirmation.contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indien u vragen heeft over uw bestelling kunt u ons e-mailen op <a href=\"mailto:webshop", "@", "wijnendeclerck.be\">webshop", "@", "wijnendeclerck.be</a> of telefonisch op +32(0)56 35 65 11."])},
  "mail-order-confirmation.delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzending"])},
  "mail-order-confirmation.delivery-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendadres"])},
  "mail-order-confirmation.gift-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadeau boodschap"])},
  "mail-order-confirmation.intro-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hartelijk dank voor uw bestelling. Wij hebben deze goed ontvangen. De bestelling zal binnen de 5 werkdagen geleverd worden."])},
  "mail-order-confirmation.intro-pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hartelijk dank voor uw bestelling. Uw bestelling wordt klaargezet voor afhaling. "])},
  "mail-order-confirmation.invoice-addres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factuuradres"])},
  "mail-order-confirmation.item-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art. nr."])},
  "mail-order-confirmation.item-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs"])},
  "mail-order-confirmation.item-quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal"])},
  "mail-order-confirmation.order-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw bestelling"])},
  "mail-order-confirmation.payment-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaalmethode"])},
  "mail-order-confirmation.pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afhalen in winkel"])},
  "mail-order-confirmation.pickup-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewenste afhaaldatum"])},
  "mail-order-confirmation.price-shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzending en verwerking"])},
  "mail-order-confirmation.price-subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotaal"])},
  "mail-order-confirmation.price-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eindtotaal"])},
  "mail-order-confirmation.price-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTW"])},
  "mail-order-confirmation.remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerkingen"])},
  "mail-order-confirmation.shipping-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendmethode"])},
  "mail-order-confirmation.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestiging van je bestelling"])},
  "mail.reset.button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord instellen"])},
  "mail.reset.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw e-mailadres"])},
  "mail.reset.intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via onze website werd een wachtwoord reset aangevraagd voor uw account. Klik op de onderstaande knop om uw wachtwoord te resetten. Indien u deze aanvraag niet heeft gedaan, gelieve deze e-mail te negeren."])},
  "mail.reset.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je wachtwoord opnieuw instellen"])},
  "mini-cart.action.continue-shopping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verder winkelen"])},
  "mini-cart.action.go-to-checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar winkelwagen"])},
  "mini-cart.label.total-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaalprijs"])},
  "mini-cart.label.vat-excluded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTW excl."])},
  "mini-cart.label.vat-included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTW incl."])},
  "mini-cart.message.error.get-items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er deed zich een fout voor bij het ophalen. Contacteer ons indien het probleem blijft aanhouden."])},
  "mini-cart.message.warning.no-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen producten gevonden."])},
  "newsletters.message.error.subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inschrijving is mislukt. Contacteer ons indien het probleem blijft aanhouden."])},
  "newsletters.message.error.unsubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitschrijving is mislukt."])},
  "newsletters.message.info.update-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het kan tot 10 seconden duren voor het systeem de inschrijving reflecteert, dus u hoeft niet meermaals in of uit te schrijven."])},
  "newsletters.message.success.subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inschrijving is succesvol uitgevoerd."])},
  "newsletters.message.success.unsubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitschrijving is succesvol uitgevoerd."])},
  "newsletters.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnementoptie"])},
  "newsletter-subscription.form.label.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling"])},
  "order-detail.action.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar overzicht"])},
  "order-detail.label.delivery-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres van levering"])},
  "order-detail.label.delivery-and-processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzending en verwerking"])},
  "order-detail.label.delivery-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzendmethode"])},
  "order-detail.label.gift-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadeau boodschap"])},
  "order-detail.label.invoice-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factuuradres"])},
  "order-detail.label.payment-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaalmethode"])},
  "order-detail.label.pickup-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afhaaldatum"])},
  "order-detail.label.product-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product naam"])},
  "order-detail.label.product-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijs"])},
  "order-detail.label.product-quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal"])},
  "order-detail.label.product-total-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotaal"])},
  "order-detail.label.reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referentie"])},
  "order-detail.label.remarks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerkingen"])},
  "order-detail.label.sub-total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotaal"])},
  "order-detail.label.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eindtotaal"])},
  "order-detail.label.vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTW"])},
  "order-detail.message.warning.no-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen producten gevonden."])},
  "order-detail.title.ordered-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelde artikelen"])},
  "order-list.action.re-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw bestellen"])},
  "order-list.action.show-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestelling bekijken"])},
  "order-list.label.actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acties"])},
  "order-list.label.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "order-list.label.method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "order-list.label.ordernumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordernummer"])},
  "order-list.label.total-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal bedrag"])},
  "order-list.label.total-quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal producten"])},
  "order-list.message.error.add-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er deed zich een fout voor bij het toevoegen. Contacteer ons indien het probleem blijft aanhouden."])},
  "order-list.message.info.quantity-update-by-stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product(en) werden aangepast aan de beschikbare hoeveelheid."])},
  "order-list.message.success.add-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Producten werden toegevoegd aan de winkelwagen."])},
  "order-list.message.warning.no-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen orders gevonden."])},
  "order.message.warning.no-re-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan niet opnieuw worden besteld."])},
  "page.title.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "popular-categories.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Populaire categorieën:"])},
  "product.action.add-to-basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen aan winkelwagen"])},
  "product.action.download-technical-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download technische fiche"])},
  "product.action.request-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkel op aanvraag"])},
  "product-availability.form.action.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versturen"])},
  "product-availability.form.placeholder.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer hier je email adres in"])},
  "product.discount.amount-per-quantity": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vanaf ", _interpolate(_named("MinimumQuantity")), " flessen: - ", _interpolate(_named("DiscountValue")), "/fles"])},
  "product.discount.amount-per-total": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vanaf ", _interpolate(_named("MinimumQuantity")), " flessen: ", _interpolate(_named("DiscountValue")), " korting op totaal"])},
  "product.discount.percentage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vanaf ", _interpolate(_named("MinimumQuantity")), " flessen: ", _interpolate(_named("DiscountValue")), "% korting"])},
  "product.label.order-quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal"])},
  "product.message.error.add-to-cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er deed zich een fout voor bij het toevoegen. Contacteer ons indien het probleem blijft aanhouden."])},
  "product.message.info.free-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis verzending in België vanaf €300"])},
  "product.message.info.pickup-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkel in de winkel af te halen"])},
  "product.message.info.quantity-not-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gewenste hoeveelheid is op dit moment niet beschikbaar."])},
  "product.message.info.quantity-update-by-stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product werd aangepast aan de beschikbare hoeveelheid."])},
  "product.message.success.add-to-cart": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("Product")), " werd toegevoegd aan de winkelwagen."])},
  "product.message.warning.maximum-order-quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het maximum aantal werd bereikt."])},
  "product.message.warning.minimum-order-quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het minimum aantal werd bereikt."])},
  "product.property.grapes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druif"])},
  "product.property.volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhoud"])},
  "product.status.not-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet in productie"])},
  "product.status.out-of-stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdelijk niet in stock"])},
  "product.status.promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo"])},
  "promotions.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promoties"])},
  "promotion.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promoties"])},
  "registration.form.action.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account aanmaken"])},
  "registration.form.label.city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stad/Gemeente"])},
  "registration.form.label.company-form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vennootschapsvorm"])},
  "registration.form.label.company-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrijfsnaam"])},
  "registration.form.label.confirm-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig wachtwoord"])},
  "registration.form.label.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "registration.form.label.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
  "registration.form.label.first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
  "registration.form.label.last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
  "registration.form.label.new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
  "registration.form.label.newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inschrijven op de nieuwsbrief"])},
  "registration.form.label.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huisnummer"])},
  "registration.form.label.number-extension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoeging"])},
  "registration.form.label.phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummer"])},
  "registration.form.label.postal-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postcode"])},
  "registration.form.label.street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straatnaam"])},
  "registration.form.label.vat-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BTW nummer"])},
  "registration.form.placeholder.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer een land"])},
  "registration.mail.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw registratie bij Wijnen De Clerck"])}
}
export default resource